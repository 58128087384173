export default {
    /**
     * 公司信息
     */
    /** 公司名称 */
    name: '北京云智轩科技有限公司',
    /** 公司logo */
    logopath: '../assets/img/logo.png',
    /** 公司微信号 */
    wechat: 'AIoT-ZD',
    /** 公司邮箱 */
    email: 'liuzhengdao@aiotyzx.com',
    /** 公司手机 */
    phone: '18810639014',
    /** 公司电话 */
    tel: '010-53340601',
    /** 公司地址 */
    address: '北京市顺义区赵工路38号院4号楼1至5层101内2层D2226号',
    /** 备案号 */
    keepOnRecordIcp: '京ICP备2020040595号-2',
    /** 百度地图相关信息 */

    BDMapInfo: {
        longitude: 116.545375,
        latitude: 40.106682,
    }
}